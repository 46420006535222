<template>
  <div class="page-content">
    <div style="background-color: #FFFFFF;padding: 20px;margin-top: 25px;" v-if="data">
      <el-table :data="data.content" stripe tooltip-effect="light" class="large-font-table">
        <el-table-column prop="data.name" label="展会名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="data.auditingDate" label="审批日期" width="120"></el-table-column>
        <el-table-column prop="data.holdingDate" label="举办日期" width="230"></el-table-column>
        <el-table-column prop="data.adress" label="举办地区" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="data.size" label="展览面积" width="100" show-overflow-tooltip>
          <span slot-scope="{row}">{{row.data.size}}㎡</span>
        </el-table-column>
        <el-table-column prop="data.content" label="展出内容" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";

export default {
  name: "MofList",
  data(){
    return {
      data:null
    }
  },
  created() {
    this.loadData(1)
  },
  methods:{
    async loadData(page){
      this.data=(await api.listMof({page:page-1,size:10})).data
    }
  }
}
</script>

<style scoped>

</style>
